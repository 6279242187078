<template>
  <loading></loading>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker"

export default {
  mounted() {
    const orgCode = this.$route.params.org_code
    const checker = new LiffChecker(orgCode)
    checker.portalCheck()
  },
}
</script>
